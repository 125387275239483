import React from "react"

import { withMembershipSuccess } from "./withMembershipSuccess"
import { Container, Button, Title, Emoji, Link } from "./Membership.styled"
import { Toast } from "../../Toast/Toast"

export const MembershipSuccess = withMembershipSuccess(
  ({ title, content }: Props): JSX.Element => (
    <>
      <Container width={`md`}>
        <Title>{title}</Title>
        <Emoji>🎉</Emoji>
        {content}
        <Button as={Link} to={"/mentors"} size={`large`} theme={`primary`} colour={`dark`}>
          Browse Mentors
        </Button>
      </Container>
    </>
  )
)

export interface Props {
  title?: string
  content?: any
  awaitingUpdate: boolean
  setAwaitingUpdate: (val: boolean) => void
}
