import React, { useMemo } from "react"
import { navigate } from "gatsby"

import { useCore } from "../../../hooks/useCore"
import { useApp } from "../../../hooks/useApp"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withMembershipSuccess = Component =>
  React.memo(({ name = "MembershipSuccess", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { member, ready } = useApp()
    const { trackCustomEvent } = useAnalytics()

    const title = page?.title
    const content = sanityContent(page?.content)

    const params = new URLSearchParams(location?.search)
    const redirectUrl = params.get("redirectUrl") || false
    const plan = params.get("plan") || false

    if (ready) {
      trackCustomEvent("membershipSuccess", { plan })
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true, state: { shouldRefreshMembership: true } })
      }
    }

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} />, [member])
  })
